import * as React from "react"
import { Link } from "gatsby"
import '../styles/styles.scss';
import Header from '../components/StrapiComponents/Header';
import Footer from '../components/StrapiComponents/Footer';


// markup
const NotFoundPage = () => {
  const siteMetadata = {
    noIndex: true,
    metaTitle: 404,
    canonicalURL: "/404",
    nofollow: true,
    metaDescription: "Changefirst, the Leading Change Management Company helping Your Business implement Agile Change, and offering a revolutionary new way to meet the challenge!",
    title: "",
    route: "/404"
  }

  return (
    <div className="not-found">
      <Header siteMetadata={siteMetadata}/>
      <main>
        <title>Not found</title>
        <h1>Page not found</h1>
        <p>
          Sorry, we couldn’t find what you were looking for.
          <br />
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </main>
      <Footer />
    </div>
  )
}

export default NotFoundPage
